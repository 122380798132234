import financial from "../images/usecase-icons/financial.png"
import ad from "../images/usecase-icons/ad.png"
import mfg from "../images/usecase-icons/mfg.png"
import business from "../images/usecase-icons/business.png"
import medical from "../images/usecase-icons/medical.png"
import travel from "../images/usecase-icons/travel.png"
import auto from "../images/usecase-icons/automotive.png"
import tele from "../images/usecase-icons/tele.png"

export default [
  {
    id: 1,
    name: "A",
    icon: financial,
    left: 220,
    top: 250,
    size: 42,
    x: 10,
    color: "#F3F9A7",
    url: "url(#A)",
    title: "FinTech",
    description: "Fraud & AML detection, Financial investigation, KYC, Financial market predictions, Faster and secure remittance, Application fulfillment (Loans, insurance, etc..), Compliance, reporting, and much more!",
  },
  {
    id: 2,
    name: "B",
    icon: ad,
    left: 320,
    top: 40,
    size: 40,
    x: 20,
    color: "#D32F2F",
    url: "url(#B)",
    title: "Ad Tech",
    description: "Customer profiling, Data management and governance, and Market analytics",
  },
  {
    id: 3,
    name: "C",
    icon: mfg,
    left: 500,
    top: 10,
    size: 40,
    x: 30,
    color: "#FFCC80",
    url: "url(#C)",
    title: "Supply Chain / Manufacturing",
    description: "Supply chain optimization, Resource optimization, Trade financing, and much more!",
  },
  {
    id: 4,
    name: "D",
    icon: business,
    left: 780,
    top: 130,
    size: 40,
    x: 40,
    color: "#F48FB1",
    url: "url(#D)",
    title: "Business",
    description: "Be able to do safe and efficient global trade, achieve holisitic overview of your business health, Tax & cash flow optimization, and Employee & customer profiling",
  },
  {
    id: 5,
    name: "E",
    icon: medical,
    left: 790,
    top: 240,
    size: 40,
    x: 50,
    color: "#AED581",
    url: "url(#E)",
    title: "Medical",
    description: "Secure transfer of Medical records and keeping accountability, Data governance & analysis, predictive analysis In patients health and infections, etc.",
  },
  {
    id: 6,
    name: "F",
    icon: travel,
    left: 460,
    top: 450,
    size: 40,
    x: 60,
    color: "#00ACC1",
    url: "url(#F)",
    title: "Travel",
    description: "Provide identity management and universal ID, international wallet access, asset tracking, and much more.",
  },
  {
    id: 7,
    name: "G",
    icon: auto,
    left: 340,
    top: 360,
    size: 40,
    x: 60,
    color: "#C4E0E5",
    url: "url(#G)",
    title: "Automotive",
    description: "Autonomous Vehicles communicate with each other to ensure safety, enhance security for vehicles, and much more.",
  },
  {
    id: 8,
    name: "H",
    icon: tele,
    left: 690,
    top: 400,
    size: 40,
    x: 60,
    color: "#d9a7c7",
    url: "url(#H)",
    title: "Telecommunication",
    description: "Encrypted communication especially for emergency response, Optimized mesh networks, Self-contained ecosystems, etc.",
  },
]